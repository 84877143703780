/*
 * @Author: lg9527 13691080728@126.com
 * @Date: 2023-05-10 21:32:31
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2024-12-02 09:47:26
 * @FilePath: \zky_webVue\src\setting.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
module.exports = {
  // 站点名称
   siteTitle: '岩石地球化学数据中心',
  // 请求超时时间，毫秒（默认2分钟）
  timeout: 1200000,
  tokenKey: 'token',
  userKey: 'user',
  // 站点基路径
   //-----本地测试服务器
//  baseUrl: {
//     // 默认基路径 如新闻详情iframe用到
//     default: 'http://127.0.0.1:9991/',
//     // 后台api
//     api: 'http://127.0.0.1:9991/api/',
//     // 后台服务器图片资源
//     img: 'http://39.101.149.153:8081/'
//   },

  // baseUrl: {
  //   // 默认基路径 如新闻详情iframe用到
  //   default: 'http://localhost:8061/',
  //   // 后台api
  //   api: 'http://localhost:8061/api/',
  //   // 后台服务器图片资源
  //   img: 'http://39.101.149.153:8081/'
  // },
  
  // 三维可视化相关
  // visualized: {
  //   // web地址
  //   webUrl: 'http://39.101.149.153:8086/',
  //   // api基路径
  //   apiBaseUrl: 'http://39.101.149.153:8080/'
  // }
  //-----测试服务器
  // baseUrl: {
  //   // 默认基路径 如新闻详情iframe用到
  //   default: 'http://123.249.2.158:82/',
  //   // 后台api
  //   api: 'http://123.249.2.158:82/api/',
  //   // 后台服务器图片资源
  //   img: 'http://159.226.119.243:8081/'
  // },
  // // 三维可视化相关
  // visualized: {
  //   // web地址
  //   webUrl: 'http://123.249.2.158:8086/',
  //   // api基路径
  //   apiBaseUrl: 'http://123.249.2.158:8071/'
  // }
  //-----生成服务器
  baseUrl: {
    // 默认基路径 如新闻详情iframe用到
    default: 'http://159.226.119.243:8081/',
    // 后台api
    api: 'http://159.226.119.243:8081/api/',
    // 后台服务器图片资源
    img: 'http://159.226.119.243:8081/'
  },
  // 三维可视化相关
  visualized: {
    // web地址
    webUrl: 'http://159.226.119.243:8086/',
    // api基路径
    apiBaseUrl: 'http://159.226.119.243:8071/'
  }

}
